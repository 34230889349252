import { Rule } from "antd/lib/form";
import { isNullOrEmpty } from "../utils";

declare global {
    interface ImportMeta {
        env: {
            MODE: string;
            DEFAULT_ROUTE: string;
        }
    }
}

const env = import.meta.env;

export const DEVMODE = env.MODE === "development";
export const DEFAULT_ROUTE = env.DEFAULT_ROUTE;

export type Id = number | string;

export interface Entity {
    id: Id;
    name?: string;
}

export interface Crumb {
    path?: string;
    label: string;
}

export interface AppContextState {
    initialized: boolean;
    user?: User;
    projects: UserProject[];
    activeProjectId?: Id;
    activeProject?: UserProject;
    paramTypes: ParamType[];
    availableParamTypes: ParamType[];
    taskTypes: TaskType[];
    apiServices: ApiService[];
    runningTasks: Task[];
    downloadToken?: Token | null,
    onProjectChange?: (id: number) => void;
    getProjects: () => void;
    serviceIsAvailable: (serviceName: string) => boolean;
    logout: () => void;
}

export enum UserRole {
    Admin = "Admin",
    User = "User"
}

export interface User {
    id: Id;
    preferredUsername: string;
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    enabled: boolean;
    roles: UserRole[];
}

export const userIsAdmin = (user: User | null | undefined): boolean => !!user && user.roles.includes(UserRole.Admin);

export const userFullName = (user: User) => `${user.firstName} ${user.lastName}`.trim();

export enum TokenKind {
    User = "user",
    System = "system",
    Download = "download"
}

export interface Token {
    id: string;
    userId: string;
    name: string;
    kind?: TokenKind | null;
    created: Date;
    ttl: number;
    expiration: number;
}

export const EmptyToken = {
    id: "",
    userId: "",
    name: "",
    expiration: -1,
};

export interface UserProject {
    label: string;
    name: string;
    value: Id;
}

export interface Project {
    id: Id;
    owner: User;
    users: User[];
    created: string;
    modified: null;
    active: boolean;
    client: string;
    input_type: string;
    lang: string;
    name: string;
    notes: string;
    order_code: string;
    order_label: string;
    order_type: string;
    output_type: string;
    tags: string[];
}

export interface ProjectId {
    id: Id;
}

export interface MetadataInterface {
    metadata?: any;
}

export interface ParamTask {
    type: "IN" | "OUT";
    task: Task;
}

export interface Parameter extends MetadataInterface {
    id?: number;
    label: string;
    data: any;
    created?: string;
    modified?: string;
    paramType: ParamType;
    project: ProjectId;
    parentId?: number;
    relevant?: boolean;
    tasks?: ParamTask[]
}

export interface ParameterViewProps {
    param: Parameter;
}

export interface OptionalParameterViewProps {
    param?: Parameter;
}

export interface ParameterEditProps {
    param: Parameter;
    readonly?: boolean;
}

export interface ParamType {
    definition?: { value: string }
    description?: string;
    id: Id;
    name: string;
    fileLike: boolean;
    internal: boolean;
    hidden: boolean;
}

export interface ParamMetadata {
    type: string;
    required: boolean;
    advanced: boolean;
    defaultValue?: string;
    values?: string[];
}

export interface TaskType {
    id: Id;
    name: string;
    version: string;
    type: string;
    description: string;
    available: boolean;
    enabled: boolean;
    input: { [key: string]: ParamMetadata }
}

export enum TaskStatus {
    CREATED = 'CREATED',
    SCHEDULED = 'SCHEDULED',
    ACTIVE = 'ACTIVE',
    COMPLETED = 'COMPLETED',
    STOPPED = 'STOPPED',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    ZOMBIE = 'ZOMBIE'
}

export const TaskStatuses = Object.values(TaskStatus);

export interface TaskIO extends MetadataInterface {
    id: number;
    type: string;
    label: string;
    value: any;
}

export interface TaskFormData {
    label?: string;
    scheduled?: string;
    taskType: TaskType;
    input?: { [key: string]: any }
}

export type TaskBase = {
    id: Id;
    created: string;
    scheduled: string;
    modified: string;
    started: string;
    ended: string;
    error: string;
    input: { [key: string]: TaskIO }
    output: { [key: string]: TaskIO }
    errors: { [key: string]: TaskIO } | null | undefined;
    label: string;
    notes: string;
    progress?: number,
    statusMessage: string;
    taskType: TaskType;
    user: User;
    project: Project;
};

export type CreatedTask = TaskBase & {
    status: TaskStatus.CREATED;
};

export type ScheduledTask = TaskBase & {
    status: TaskStatus.SCHEDULED;
};

export type ActiveTask = TaskBase & {
    status: TaskStatus.ACTIVE;
};

export type CompletedTask = TaskBase & {
    status: TaskStatus.COMPLETED;
};

export type StoppedTask = TaskBase & {
    status: TaskStatus.STOPPED;
};

export type WarningTask = TaskBase & {
    status: TaskStatus.WARNING;
};

export type ErrorTask = TaskBase & {
    status: TaskStatus.ERROR;
};

export type ZombieTask = TaskBase & {
    status: TaskStatus.ZOMBIE;
};

export type Task = CreatedTask | ScheduledTask | ActiveTask | CompletedTask | StoppedTask | WarningTask | ErrorTask | ZombieTask;

export namespace Task {
    export function isEditable(task: Task | undefined | null): boolean {
        if (!task) {
            return true;
        }
        return ![TaskStatus.SCHEDULED, TaskStatus.ACTIVE].includes(task.status);
    }

    export function hasErrorParam(task: Task): boolean {
        return !isNullOrEmpty(task.errors);
    }
}

export interface FormField {
    name: string;
    label?: string;
    rules: Rule[];
    source?: { url: string; labelKey: string; valueKey: string }
    component: { name: string; props: any }
}

export interface ApiService {
    name: string;
    prefix: string;
    version: string;
    paths?: string[];
}

export interface ApiServiceMap {
    [key: string]: ApiService;
}

export interface StateOfHealthComponent {
    status: string;
    details: any;
}

export interface StateOfHealth {
    status: string;
    components: { [key: string]: StateOfHealthComponent }
}

export interface GenericComponentDef<T> {
    component: React.ComponentType<T>;
    props: { [key: string]: any }
}

export interface ComponentDef extends GenericComponentDef<any> { }

export interface ParameterComponentDef extends ComponentDef {
    required: boolean;
}

export interface TaskComponentMap<T> {
    [key: string]: GenericComponentDef<T>;
}

export interface ParameterComponentMap {
    [key: string]: ParameterComponentDef;
}

export interface TaskParameterComponentMap {
    [key: string]: ComponentDef;
}

/* eslint-disable no-multi-spaces */
export interface Patent {
    id: string;
    title: string;                              // "METHOD FOR PRODUCING AN ALCOHOLIC BEVERAGE BASED ON TEQUILA AND VODKA"
    assignee: string[];                         // ["BALEARIC AFFAIR"]
    abstract: string;                           // "The present invention relates to a novel method for producing an alcoholic beverage based on tequila and vodka, comprising the following steps: (a) purifying (5, 6) the primary alcohol compounds of vodka (1) and tequila (2); (b) mixing (7, 8) the purified alcohol compounds of vodka (1) and tequila (2) with their corresponding aromatic components of hop (3) and citruses (4), and macerating the obtained mixtures at room temperature; (c) distilling (9, 10) the macerated mixtures of vodka (1) and tequila (2) obtained in the previous step; and (d) mixing (11) the distillates of vodka (1) and tequila (2) with one another, thereby obtaining the final beverage product. The main advantage of the invention is that the beverage obtained as a final product has a highly integrated aromatic composition, with a well-rounded flavor on the palate and well-balanced acidity and bitterness, and it is fairly but not overly sweet."
}
/* eslint-enable no-multi-spaces */

/* eslint-disable no-multi-spaces */
export interface PatentDocument extends Patent {
    claims?: string[];                          // ["1. A method for producing an alcoholic beverage ba…er, thereby obtaining the final beverage product.", "2. The method according to the preceding claim, wh…d product temperature equal to or less than -5°C.", "3. The method according to the preceding claim, wh… step of filtration by means of cellulose plates.", "4. The method according to any of the preceding cl…vated carbon and/or by means of cellulose plates.", "5. The method according to any of the preceding cl…3) and citrus flavors (4) are subsequently added.", "6. The method according to any of the preceding cl…) is selected from grapefruit, lemon and/or lime.", "7. The method according to any of the preceding cl…used for 300 liters of vodka (1) purified to 40%.", "8. The method according to any of claims 5 to 7, w… time period of fifteen days at room temperature.", "9. The method according to any of claims 5 to 8, w…om temperature for a time period of fifteen days.", "10. The method according to any of the preceding c…undergo condensation equal to or less than 105°C.", "11. The method according to any of the preceding c…ater at a temperature equal to or less than 10°C.", "12. The method according to any of the preceding c…heads, heart and tails of said distillation (10).", "13. The method according to the preceding claim, w… distilled vapors is equal to or less than 105°C.", "14. The method according to any of the preceding c…e is controlled such that it does not exceed 3°C.", "15. An alcoholic beverage based on tequila and vod… method according to any of the preceding claims."]
    description: string;                        //  "=== FIELD OF THE INVENTION ===\nThe present inven
    docdb_family_id: string;                    // "54015788"
    inventor: string[];                         // ["CHAPARRO FERNÁNDEZ, DAVID"]
    filing_date?: Date;                         // "2016-07-08"
    priority_date?: Date;                       // "2015-07-10"
    publish_date?: Date;                        // "2018-05-16"
    classes?: { [key: string]: string[] }
    links?: { [key: string]: string }
    backward_citations?: string[];
}
/* eslint-enable no-multi-spaces */

export namespace PatentDocument {
    export const getClassItems = (patent: PatentDocument, name: string): string[] => {
        if (!patent.classes || !patent.classes[name]) {
            return [];
        }
        return patent.classes[name];
    };

    export const getParts = (text: string | string[] | undefined) => {
        if (!text) {
            return [];
        }

        return typeof (text) === "string" ? text.split("\n") : text;
    };
}

export const EmptyPatent = {
    id: '',
    title: '',
    abstract: '',
    description: '',
    assignee: [],
    docdb_family_id: '',
    inventor: []
};

export interface Facet<T> {
    name: T;
    count: number;
}

export interface FacetObject<T> {
    [key: string]: Facet<T>[];
}

export interface SearchFacet {
    facet_fields: FacetObject<string>,
    facet_ranges: FacetObject<number>
}

export type Pair<T, K> = [T, K];

