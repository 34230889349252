import { useCallback, useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router';

import message from 'antd/es/message';

import { getParameterFile, saveParamAndValue, saveAsNewParamAndValue } from '../api';

import { Parameter } from '../constants/types';
import useStateCallback from './useStateCallback';

export interface ParameterState {
    param: Parameter;
    setParam: (param: Parameter) => void;
    data: string;
    setData: (data: string, cb?: (data: string) => void) => void;
    saving: boolean;
    loading: boolean;
    save: () => Promise<void>;
    saveParam: () => Promise<void>; // Save only param, not blob
    saveAsNewParam: () => Promise<void>;
}

export interface useParameterProps {
    initialParam: Parameter;
    fetchData?: boolean;
    fileType?: string;
}

const getBlob = (param: Parameter, data: string, fetchData = true, fileType = 'plain/text') => {
    return param.paramType.fileLike && fetchData
        ? {
              blob: new Blob([data], { type: fileType }),
              name: `${param?.data.path}/${param?.data.name}`,
          }
        : {
              blob: undefined,
              name: undefined,
          };
};

export const useParameter = (initialParam: Parameter, fetchData = true, fileType = 'plain/text'): ParameterState => {
    const [param, setParam] = useState<Parameter>(initialParam);
    const [saving, setSaving] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useStateCallback<string>('');
    const navigate = useNavigate();
    const isSubscribed = useRef(true);

    useEffect(() => {
        if (!initialParam) {
            return;
        }
        setParam(initialParam);
        if (initialParam.paramType.fileLike && fetchData) {
            setLoading(true);
            getParameterFile(initialParam.id!).then((d) => {
                if (isSubscribed.current) {
                    setLoading(false);
                    setData(d);
                }
            });
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [initialParam, isSubscribed, fetchData, setData]);

    const saveRef = useRef<() => Promise<void>>(() => Promise.resolve());
    const saveParamRef = useRef<() => Promise<void>>(() => Promise.resolve());
    const saveAsNewParamRef = useRef<() => Promise<void>>(() => Promise.resolve());

    useEffect(() => {
        saveRef.current = () => {
            setSaving(true);
            const { blob, name } = getBlob(param, data, fetchData, fileType);
            return saveParamAndValue(param, blob, name)
                .then(() => {
                    message.success('Parameter saved');
                })
                .catch(() => {
                    message.error('Unable to save param');
                })
                .finally(() => isSubscribed.current && setSaving(false));
        };
    }, [param, data, fetchData, fileType]);

    useEffect(() => {
        saveParamRef.current = () => {
            setSaving(true);
            return saveParamAndValue(param)
                .then(() => {
                    message.success('Parameter saved');
                })
                .catch(() => {
                    message.error('Unable to save param');
                })
                .finally(() => isSubscribed.current && setSaving(false));
        };
    }, [param]);

    useEffect(() => {
        saveAsNewParamRef.current = () => {
            setSaving(true);
            const { blob, name } = getBlob(param, data, fetchData, fileType);
            return saveAsNewParamAndValue(param, blob, name)
                .then((p) => {
                    message.success('Parameter saved');
                    if (p.id !== initialParam.id) {
                        navigate(`/parameters/${param.id}`);
                    }
                })
                .catch(() => {
                    message.error('Unable to save param.');
                })
                .finally(() => isSubscribed.current && setSaving(false));
        };
    }, [param, initialParam.id, data, fetchData, fileType, navigate]);

    const save = () => {
        return saveRef.current();
    };

    const saveParam = () => {
        return saveParamRef.current();
    };

    const saveAsNewParam = () => {
        return saveAsNewParamRef.current();
    };

    return {
        param,
        setParam,
        data,
        setData,
        saving,
        loading,
        save,
        saveParam,
        saveAsNewParam,
    };
};
