import React, { useState, useContext, useEffect, useCallback } from 'react';

import FileSaver from 'file-saver';
import { ParameterEditProps } from '../../../constants/types';
import { downloadParameterFile } from '../../../api';

import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import Radio from 'antd/es/radio';
import Space from 'antd/es/space';
import message from 'antd/es/message';

import TableOutlined from '@ant-design/icons/TableOutlined';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import DownOutlined from '@ant-design/icons/DownOutlined';
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import FileExcelTwoTone from '@ant-design/icons/FileExcelTwoTone';

import RankingGrammarTextEditor from './RankingGrammarTextEditor';
import RankingGrammarEditor from './RankingGrammarEditor';
import { RunTaskDialog } from '../../tasks';
import { AppContext } from '../../../contexts';
import { filterTaskTypeByInputType } from '../../../utils';
import { useParameter } from '../../../hooks/useParameter';
import ChemicalToRegexTool from '../../tools/ChemicalToRegexTool';
import TermsDistributionTool from '../../tools/TermsDistributionTool';
import { Grammar, GrammarParsingError, GrammarState } from './grammar';

enum Mode {
    EDITOR = 'EDITOR',
    TEXT = 'TEXT',
}

interface FakeButtonProps {
    onClick: () => void;
}

const FakeButton: React.FC<FakeButtonProps> = ({ onClick, children }) => (
    <div onClick={() => onClick()}>{children}</div>
);

const RankingGrammarEdit: React.FC<ParameterEditProps> = ({ param: initialParam }) => {
    const { user, taskTypes, downloadToken } = useContext(AppContext);
    const { param, setParam, data, setData, saving, loading, save, saveAsNewParam } =
        useParameter(initialParam);
    const [mode, setMode] = useState<Mode>(Mode.EDITOR);

    const [grammar, setGrammar] = useState<Grammar>(GrammarState.createEmpty());
    const [error, setError] = useState<GrammarParsingError | null>(null);
    const [text, setText] = useState<string>(data);

    useEffect(() => {
        if (data) {
            const [grm, err] = GrammarState.createFromString(data);
            setGrammar(grm);
            setError(err);
            setText(data);
        }
    }, [data]);

    const onModeChange = (m: Mode) => {
        if (m === mode) {
            return;
        }
        if (m === Mode.TEXT) {
            setText(GrammarState.toString(grammar));
        }
        if (m === Mode.EDITOR) {
            const [grm, err] = GrammarState.createFromString(text);
            setGrammar(grm);
            setError(err);
        }
        setMode(m);
    };

    /*
     * Save param wrappers
     */
    const updateData = () => {
        const d = mode === Mode.TEXT ? text : GrammarState.toString(grammar);
        return new Promise((resolve) => {
            setData(d, (_data) => resolve(null));
        });
    };

    const _save = () => {
        return updateData().then(save);
    };

    const _saveAsNewParam = () => {
        return updateData().then(saveAsNewParam);
    };

    const grammarTasks = filterTaskTypeByInputType(taskTypes, 'RankingGrammar');
    const runTaskEl =
        grammarTasks.length > 0 && grammarTasks.length === 1 ? (
            <RunTaskDialog
                taskType={grammarTasks[0]}
                param={param}
                hiddenFields={['label']}
                beforeShow={_saveAsNewParam}
            />
        ) : (
            <Dropdown
                trigger={['click']}
                placement="top"
                overlay={
                    <Menu>
                        {grammarTasks.map((taskType, index) => {
                            return (
                                <Menu.Item key={`${taskType.id}_${index}`}>
                                    <RunTaskDialog
                                        component={FakeButton}
                                        taskType={taskType}
                                        param={param}
                                        hiddenFields={['label']}
                                        beforeShow={_saveAsNewParam}
                                    />
                                </Menu.Item>
                            );
                        })}
                    </Menu>
                }
            >
                <Button>
                    <Space>
                        <>Run Task</>
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
        );

    const createNewRow = (groups: string[]): boolean => {
        const grm = GrammarState.appendItem(grammar, {
            text: groups.join(','),
            annotation: '',
            weight: 1.0,
        });
        setGrammar(grm);
        return true;
    };

    const exportGrammarXlsx = async () => {
        try {
            const grm = mode === Mode.EDITOR ? grammar : GrammarState.fromString(text);
            const workbook = GrammarState.toXlsx(grm);
            workbook.creator = user ? `${user.firstName} ${user.lastName}` : 'Unknown';
            workbook.created = new Date();
            workbook.modified = new Date();

            // write to a new buffer
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
            });
            FileSaver.saveAs(blob, `${param.label}.xlsx`);
        } catch (err) {
            console.warn(err);
            message.error('Unable to process grammar');
        }
    };

    const _downloadParameterFile = () => {
        if (param.id) {
            downloadParameterFile(param.id, downloadToken);
        }
    };

    return (
        <div className="param-edit param-grammar">
            <div className="param-edit-header">
                <div className="label">
                    <Input
                        value={param.label}
                        onChange={(e) => setParam({ ...param, label: e.target.value })}
                    />
                </div>
                <div className="controls">
                    <Space>
                        <Radio.Group
                            value={mode}
                            onChange={(e) => onModeChange(e.target.value as Mode)}
                        >
                            <Radio.Button value={Mode.EDITOR}>
                                <TableOutlined />
                            </Radio.Button>
                            <Radio.Button value={Mode.TEXT}>
                                <FileTextOutlined />
                            </Radio.Button>
                        </Radio.Group>
                        <ChemicalToRegexTool />
                        <TermsDistributionTool
                            kind="ranking"
                            actions={[
                                {
                                    label: 'Add Group',
                                    type: 'primary',
                                    onClick: (output: string[]) => createNewRow(output),
                                },
                            ]}
                        />
                    </Space>
                </div>
            </div>

            {mode === Mode.EDITOR ? (
                <RankingGrammarEditor
                    loading={loading}
                    value={grammar}
                    error={error}
                    onChange={setGrammar}
                />
            ) : (
                <RankingGrammarTextEditor
                    loading={loading}
                    value={text}
                    onChange={setText}
                />
            )}

            <div className="param-edit-footer">
                <div className="actions-toolbar">
                    <Space>
                        <Dropdown.Button
                            type="primary"
                            placement="topRight"
                            disabled={saving}
                            loading={loading || saving}
                            onClick={_saveAsNewParam}
                            icon={<DownOutlined />}
                            overlay={
                                <Menu
                                    items={[
                                        {
                                            label: 'Override',
                                            key: 'override',
                                            icon: <SaveOutlined />,
                                            onClick: ({ key }) => {
                                                if (key === 'override') {
                                                    _save();
                                                }
                                            },
                                        },
                                    ]}
                                />
                            }
                        >
                            Save
                        </Dropdown.Button>
                        {runTaskEl}
                    </Space>
                    <Space>
                        <Button
                            icon={<FileExcelTwoTone twoToneColor="#52c41a" />}
                            onClick={() => param.id && exportGrammarXlsx()}
                        >
                            Export (excel)
                        </Button>

                        <Button
                            icon={<FileTextOutlined />}
                            onClick={_downloadParameterFile}
                        >
                            Export (text)
                        </Button>
                    </Space>
                </div>
            </div>
        </div>
    );
};

export default RankingGrammarEdit;
