import React, { PropsWithChildren, useContext } from "react";

import { Navigate } from "react-router-dom";

import { AppContext } from "../contexts";

interface ServiceRouteProps {
    service: string;
    defaultRoute: string;
}

const ServiceRoute: React.FC<PropsWithChildren<ServiceRouteProps>> = ({ service, defaultRoute, children }) => {
    const { serviceIsAvailable } = useContext(AppContext);

    return serviceIsAvailable(service)
        ? <>{children}</>
        : <Navigate to={defaultRoute} />;
};

export default ServiceRoute;
