import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

export const UNIX_TIME_ORIGIN = "1970-01-01 00:00:00";

export const PATSEARCH_API_KEY_HEADER = "X-Patsearch-API-Key";

export const MONACO_CONFIG: monaco.editor.IStandaloneEditorConstructionOptions = {
    wordWrap: 'on',
    detectIndentation: false,
    tabSize: 8,
    renderWhitespace: 'all',
    insertSpaces: false,
    useTabStops: true,
    fontSize: 13,
    theme: "r2",
};
