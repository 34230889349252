import React, { useCallback, useEffect, useState } from 'react';

import { GrammarItem, RankingGrammarEditorProps } from './types';
import { Grammar, GrammarState } from './grammar';

import Alert from 'antd/es/alert';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Space from 'antd/es/space';
import Spin from 'antd/es/spin';

import PushpinOutlined from '@ant-design/icons/PushpinOutlined';
import DeleteButton from '../../DeleteButton';

interface EditableCellProps {
    value: number | string;
    number?: boolean;
    onCellChange: (value: number | string) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    value: initialValue,
    number = false,
    onCellChange,
}: any) => {
    const [value, setValue] = useState<string | number>(initialValue || number ? 0 : '');

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const onBlur = () => {
        onCellChange(value);
    };

    if (number) {
        return (
            <InputNumber
                value={value}
                onChange={(val) => setValue(val || 0)}
                onBlur={onBlur}
            />
        );
    }

    return (
        <Input
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
            onBlur={onBlur}
        />
    );
};

interface MultiLineEditableCellProps {
    value: string;
    onCellChange: (value: string) => void;
}

const MultiLineEditableCell: React.FC<MultiLineEditableCellProps> = ({
    value: initialValue,
    onCellChange,
}) => {
    const [value, setValue] = useState(initialValue || '');

    useEffect(() => setValue(initialValue), [initialValue]);

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    };

    const onBlur = () => {
        onCellChange(value);
    };

    return (
        <Input.TextArea
            className="multi-line-editable-cell"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            autoSize={{ minRows: 1 }}
        >
            {value}
        </Input.TextArea>
    );
};

interface DeleteGroupButtonCellProps {
    item: GrammarItem;
    onClick: (item: GrammarItem) => void;
}

const DeleteGroupButtonCell: React.FC<DeleteGroupButtonCellProps> = ({ item, onClick }) => {
    return <DeleteButton size="middle" onConfirm={() => onClick(item)} />;
};

interface EditableTableProps {
    items: GrammarItem[];
    onCellChange: (rowIndex: number, columnId: string, value: number | string) => void;
    onRowDelete?: (itemIndex: number) => void;
}

const EditableTable = ({ items, onCellChange, onRowDelete }: EditableTableProps) => {
    return (
        <table className="grammar-table">
            <tbody>
                {items.map((item: GrammarItem, itemIndex: number) => {
                    return (
                        <tr key={`${itemIndex}`}>
                            <td style={{ verticalAlign: 'top' }}>
                                <MultiLineEditableCell
                                    value={item.text}
                                    onCellChange={(value) =>
                                        onCellChange(itemIndex, 'text', value)
                                    }
                                />
                            </td>
                            <td style={{ verticalAlign: 'top', width: '256px' }}>
                                <MultiLineEditableCell
                                    value={item.annotation}
                                    onCellChange={(value) =>
                                        onCellChange(itemIndex, 'annotation', value)
                                    }
                                />
                            </td>
                            <td style={{ verticalAlign: 'top', width: '96px' }}>
                                <EditableCell
                                    number
                                    value={item.weight}
                                    onCellChange={(value) =>
                                        onCellChange(itemIndex, 'weight', value)
                                    }
                                />
                            </td>
                            <td style={{ verticalAlign: 'top', width: '32px' }}>
                                <DeleteGroupButtonCell
                                    item={item}
                                    onClick={() => onRowDelete && onRowDelete(itemIndex)}
                                />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

const RankingGrammarEditor: React.FC<RankingGrammarEditorProps> = ({
    loading,
    value: grammar,
    error,
    onChange,
}) => {
    // const [error, setError] = useState<GrammarParsingError | null>(null);
    const [showErrorDetails, setShowErrorDetails] = useState<boolean>(true);
    const [stickyGroup, setStickyGroup] = useState<number>(-1);

    const setGrammar = useCallback(
        (grm: Grammar) => {
            onChange(grm);
        },
        [onChange]
    );

    const deleteGroup = useCallback(
        (groupNum: number) => {
            if (groupNum < stickyGroup) {
                setStickyGroup(stickyGroup - 1);
            }
            if (groupNum === stickyGroup) {
                setStickyGroup(-1);
            }
            const grm = GrammarState.deleteGroup(grammar, groupNum);
            setGrammar(grm);
        },
        [grammar, setGrammar, stickyGroup, setStickyGroup]
    );

    const insertGroup = useCallback(() => {
        const grm = GrammarState.addGroup(grammar);
        setGrammar(grm);
    }, [grammar, setGrammar]);

    const addItem = useCallback(
        (groupNum: number) => {
            setGrammar(GrammarState.addItem(grammar, groupNum));
        },
        [grammar, setGrammar]
    );

    const deleteItem = useCallback(
        (groupNum: number, itemIndex: number) => {
            setGrammar(GrammarState.deleteItem(grammar, groupNum, itemIndex));
        },
        [grammar, setGrammar]
    );

    const onCellChange = useCallback(
        (groupNum: number, rowIndex: number, columnId: string, val: string | number) => {
            setGrammar(GrammarState.update(grammar, groupNum, rowIndex, columnId, val));
        },
        [grammar, setGrammar]
    );

    const toggleSticky = (groupNum: number) => {
        if (stickyGroup === groupNum) {
            setStickyGroup(-1);
        } else {
            setStickyGroup(groupNum);
        }
    };

    const groupClassName = (groupNum: number): string => {
        return stickyGroup === groupNum ? 'grammar-group sticky' : 'grammar-group';
    };

    const toggleErrorDetails = () => {
        setShowErrorDetails(!showErrorDetails);
    };

    const errorDescription =
        error && showErrorDetails ? (
            <div className="grammar-error">
                <div className="error-message">
                    Line #{error.lineNum + 1}: {error.message}
                </div>
                <div className="error-detail">
                    <table>
                        {error.text.split('\n').map((line, num) => {
                            const hasError = error.lineNum === num;
                            const lineNumclassName = hasError
                                ? 'line-num error'
                                : 'line-num';
                            const className = hasError ? 'error' : '';
                            return (
                                <tr key={num}>
                                    <td className={lineNumclassName}>
                                        <code>{num + 1}</code>
                                    </td>
                                    <td className={className}>
                                        <code>{line}</code>
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </div>
            </div>
        ) : null;

    return (
        <Spin spinning={loading} size="large">
            {error && (
                <Alert
                    className="grammar-error"
                    message="Parse Grammar Error"
                    description={errorDescription}
                    action={
                        <Button size="small" danger onClick={toggleErrorDetails}>
                            Detail
                        </Button>
                    }
                    type="error"
                    showIcon
                />
            )}
            <div className="grammar-container">
                {grammar.map((items: GrammarItem[], groupNum: number) => (
                    <div key={groupNum} className={groupClassName(groupNum)}>
                        <div className="grammar-group-header">
                            <strong>Group #{groupNum + 1}</strong>
                            <Space className="controls">
                                <DeleteButton onConfirm={() => deleteGroup(groupNum)}>
                                    Delete Group
                                </DeleteButton>
                                <Button
                                    size="small"
                                    type={stickyGroup === groupNum ? 'primary' : 'default'}
                                    icon={<PushpinOutlined />}
                                    onClick={() => toggleSticky(groupNum)}
                                />
                            </Space>
                        </div>
                        <div className="grammar-group-content">
                            <EditableTable
                                items={items}
                                onCellChange={(rowIndex, columnId, val) =>
                                    onCellChange(groupNum, rowIndex, columnId, val)
                                }
                                onRowDelete={(itemIndex) => deleteItem(groupNum, itemIndex)}
                            />
                            <div className="grammar-group-footer">
                                <Button size="small" onClick={() => addItem(groupNum)}>
                                    Add Row
                                </Button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="param-edit-footer">
                <Button size="small" onClick={() => insertGroup()}>
                    Add Group
                </Button>
            </div>
        </Spin>
    );
};

export default RankingGrammarEditor;
