/* eslint-disable max-classes-per-file */
import { GrammarItem } from './types';

import cloneDeep from 'lodash/cloneDeep';
import ExcelJS from 'exceljs';

export class Grammar extends Array<GrammarItem[]> {
    hasGroup(groupNum: number): boolean {
        return !!this[groupNum];
    }
}

export class GrammarParsingError extends Error {
    text = '';

    lineNum = 0;

    constructor(text: string, lineNum: number, error: any) {
        super(error);
        this.text = text;
        this.lineNum = lineNum;
    }
}

export class GrammarItemParsingError extends Error {
    lineNum = 0;

    constructor(lineNum: number, error: any) {
        super(error);
        this.lineNum = lineNum;
    }
}

/*
 * Private helpers (can mutates object)
 */
const _clone = (grammar: Grammar): Grammar => {
    const grm = new Grammar();
    grammar.forEach((group) => {
        grm.push(cloneDeep(group));
    });
    return grm;
};

const _addItem = (grammar: Grammar, groupNum: number, item?: GrammarItem): Grammar => {
    if (!grammar.hasGroup(groupNum)) {
        grammar[groupNum] = [];
    }
    const _item = item
        ? item
        : {
              text: '',
              annotation: '',
              weight: 1.0,
          };
    grammar[groupNum].push(_item);
    return grammar;
};

const _itemFromString = (str: string, lineNum: number): [number, GrammarItem] | null => {
    try {
        const values = str.split('\t');
        const text = values[0];
        const gg = values[1].split('|');
        const num = parseFloat(gg[0]);
        const annotation = gg.length > 1 ? gg.slice(1).join('|') : '';
        const weight = parseFloat(values[2].replace(',', '.'));

        return [
            num,
            {
                text,
                annotation,
                weight,
            },
        ];
    } catch (error) {
        throw new GrammarItemParsingError(lineNum, error);
    }
};

const _itemToString = (item: GrammarItem, groupNum: number): string => {
    const g =
        item.annotation && item.annotation.length
            ? `${groupNum}|${item.annotation}`
            : `${groupNum}`;

    return `${item.text}\t${g}\t${item.weight}`;
};

export const GrammarState = {
    createEmpty: (): Grammar => {
        return new Grammar();
    },

    fromString: (str: string | null | undefined): Grammar => {
        if (!str) {
            return GrammarState.createEmpty();
        }

        const grm = new Grammar();

        const items = str
            .trim()
            .split('\n')
            .map((line, lineNum) => _itemFromString(line, lineNum));

        items.forEach((value: [number, GrammarItem] | null, index: number) => {
            if (value) {
                const groupNum = value[0] - 1;
                const grammarItem = value[1];
                _addItem(grm, groupNum, grammarItem);
            }
        });

        return grm;
    },

    createFromString: (str: string | null | undefined): [Grammar, GrammarParsingError | null] => {
        try {
            const grm = GrammarState.fromString(str);
            return [grm, null];
        } catch (err) {
            const itemErr = err as GrammarItemParsingError;
            return [
                GrammarState.createEmpty(),
                new GrammarParsingError(str || '', itemErr.lineNum, err),
            ];
        }
    },

    toString: (grammar: Grammar): string => {
        const str = grammar
            .flatMap((group: GrammarItem[], groupNumber: number) =>
                group.map((item: GrammarItem) => _itemToString(item, groupNumber + 1))
            )
            .join('\n');
        return str;
    },

    toXlsx: (grammar: Grammar): ExcelJS.Workbook => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('Ranking Grammar');

        grammar.map((items, i) => {
            // Group header
            const colNum = 4 * i + 2;
            {
                const col = sheet.getColumn(colNum);
                col.width = 30;
                const cell = sheet.getCell(2, colNum);
                cell.value = `Group #${i + 1}`;
                cell.style = {
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        bgColor: { argb: 'FFE0E0E0' },
                        fgColor: { argb: 'FFE0E0E0' },
                    },
                    border: {
                        top: { style: 'hair', color: { argb: 'FF000000' } },
                        left: { style: 'hair', color: { argb: 'FF000000' } },
                        bottom: { style: 'hair', color: { argb: 'FF000000' } },
                        right: { style: 'hair', color: { argb: 'FF000000' } },
                    },
                };
                sheet.mergeCells(2, colNum, 2, colNum + 2);
            }

            {
                const cell = sheet.getCell(3, colNum + 1);
                cell.value = 'group';
            }

            {
                const cell = sheet.getCell(3, colNum + 2);
                cell.value = 'weight';
            }

            // Group items
            items.map((item, j) => {
                const rowNum = j + 4;
                const row = sheet.getRow(rowNum);
                row.height = 50;
                const textCell = sheet.getCell(rowNum, colNum);
                textCell.value = item.text;
                textCell.style = {
                    alignment: {
                        wrapText: true,
                    },
                };
                sheet.getCell(rowNum, colNum + 1).value = item.annotation;
                sheet.getCell(rowNum, colNum + 2).value = item.weight;
            });
        });

        return workbook;
    },

    addGroup: (grammar: Grammar): Grammar => {
        const grm = _clone(grammar);
        grm.push([]);
        return grm;
    },

    deleteGroup: (grammar: Grammar, idx: number): Grammar => {
        const grm = _clone(grammar);
        grm.splice(idx, 1);
        return grm;
    },

    addItem: (grammar: Grammar, groupNum: number): Grammar => {
        return _addItem(_clone(grammar), groupNum);
    },

    deleteItem: (grammar: Grammar, groupNum: number, itemIndex: number): Grammar => {
        const grm = _clone(grammar);
        grm[groupNum].splice(itemIndex, 1);
        return grm;
    },

    update: (
        grammar: Grammar,
        groupNum: number,
        rowIndex: number,
        columnId: string,
        value: string | number
    ): Grammar => {
        const grm = _clone(grammar);
        const item = grm[groupNum][rowIndex];
        Reflect.set(item, columnId, value);
        return grm;
    },

    appendItem: (grammar: Grammar, item: GrammarItem): Grammar => {
        const groupNum = Math.max(grammar.length - 1, 0);
        return _addItem(_clone(grammar), groupNum, item);
    },
};
