import { useEffect, useState } from 'react';

import Keycloak from 'keycloak-js';

// eslint-disable-next-line new-cap
const keycloak = Keycloak('/keycloak.json');

const env = import.meta.env;

import axios from 'axios';
import Lockr from 'lockr';

import dayjs from 'dayjs';

import {
    getParamTypes,
    getTaskTypes,
    getTasks,
    getUser,
    getUserProjects,
    getApiServices,
    getDownloadToken,
    getTasksRunning
} from '../api/index';

import {
    Id,
    ParamType,
    TaskType,
    Task,
    User,
    UserProject,
    ApiService,
    Token,
    AppContextState
} from '../constants/types.js';

import find from 'lodash/find';
import { useQuery, useQueryClient } from 'react-query';

const setBearer = (token: string) => {
    axios.defaults.headers.Authorization = `Bearer ${token}`;
};

const isTokenExpired = (token: Token): boolean => {
    const expiredDate = dayjs(token.created).add(token.expiration, 'seconds');
    return expiredDate.isBefore(new Date());
};

export const useAppContext = (): AppContextState => {
    const [initialized, setInitialized] = useState<boolean>(false);
    const [user, setUser] = useState<User>();
    const [activeProjectId, setActiveProjectId] = useState<Id>();
    const [activeProject, setActiveProject] = useState<UserProject>();
    const [availableParamTypes, setAvailableParamTypes] = useState<ParamType[]>([]);
    const queryClient = useQueryClient();

    const { data: paramTypes } = useQuery<ParamType[]>(
        'param-types',
        getParamTypes,
        {
            initialData: [],
            enabled: initialized,
            refetchInterval: 22000,
            notifyOnChangeProps: ['data'],
            refetchIntervalInBackground: true,
            refetchOnWindowFocus: false,
        }
    );

    const { data: taskTypes } = useQuery<TaskType[]>(
        'task-types',
        getTaskTypes,
        {
            initialData: [],
            enabled: initialized,
            refetchInterval: 5000,
            notifyOnChangeProps: ['data'],
            refetchIntervalInBackground: true,
            refetchOnWindowFocus: false,
        }
    );

    const { data: runningTasks } = useQuery<Task[]>(
        ['runningTasks', user],
        () => user ? getTasksRunning() : [],
        {
            initialData: [],
            refetchInterval: 5000,
            notifyOnChangeProps: ['data'],
            refetchIntervalInBackground: true,
            refetchOnWindowFocus: false,
        }
    );

    const { data: projects } = useQuery<UserProject[] | undefined>(
        ['projects', user],
        () => {
            return user ? getUserProjects() : undefined;
        },
        {
            initialData: undefined,
            refetchInterval: 60000,
            notifyOnChangeProps: ['data'],
            refetchIntervalInBackground: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data: apiServices } = useQuery<ApiService[] | undefined>(
        ['apiServices', user],
        () => user ? getApiServices() : undefined,
        {
            refetchInterval: 60000,
            notifyOnChangeProps: ['data'],
            refetchIntervalInBackground: true,
            refetchOnWindowFocus: false,
        }
    );

    const { data: downloadToken } = useQuery<Token | null | undefined>(
        ['downloadToken', user],
        () => {
            if (user) {
                const token = getDownloadToken(`user_${user.id}`);
                return token;
            }
            return undefined;
        },
        {
            refetchInterval: 60000,
            notifyOnChangeProps: ['data'],
            refetchIntervalInBackground: true,
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        if (paramTypes) {
            setAvailableParamTypes(paramTypes.filter(p => !p.internal && !p.hidden));
        }
    }, [paramTypes]);

    useEffect(() => {
        if (initialized && projects !== undefined) {
            const found = find(projects, (p) => p.value === activeProjectId);
            if (found) {
                setActiveProject(found);
            } else {
                setActiveProjectId(undefined);
                setActiveProject(undefined);
            }
        }
    }, [projects, initialized, activeProjectId]);

    //
    // Set app as initialized after apiServices and downloadToken have been fetched
    //
    useEffect(() => {
        if (apiServices && downloadToken) {
            setInitialized(true);
        }
    }, [apiServices, downloadToken]);

    useEffect(() => {
        keycloak
            .init({ onLoad: 'login-required', checkLoginIframe: true })
            .then(async () => {
                setBearer(keycloak.token as string);

                const userResponse = await getUser();
                setUser({ ...userResponse });

                const currentProjectId: Id | undefined = Lockr.get('ps_project');
                setActiveProjectId(currentProjectId);

                queryClient.invalidateQueries('apiServices');
                queryClient.invalidateQueries('downloadToken');

                setInterval(() => {
                    keycloak
                        .updateToken(70)
                        .then((refreshed) => {
                            if (refreshed) setBearer(keycloak.token as string);
                        })
                        .catch(() => {
                            console.error('Failed to refresh token');
                        });
                }, 50000);
            })
            .catch((e) => {
                console.error(e);
            });
    }, [queryClient]);

    const getProjects = () => getUserProjects();

    const onProjectChange = (projectId: number) => {
        Lockr.set('ps_project', projectId);
        setActiveProjectId(projectId);
        setActiveProject(find(projects, (p) => p.value === projectId));
    };

    const serviceIsAvailable = (serviceName: string): boolean => apiServices ? apiServices.map(s => s.name).includes(serviceName) : false;

    const logout = () => keycloak.logout();

    return {
        initialized,
        user,
        projects: projects || [],
        activeProjectId,
        activeProject,
        paramTypes: paramTypes || [],
        availableParamTypes: availableParamTypes || [],
        taskTypes: taskTypes || [],
        apiServices: apiServices || [],
        runningTasks: runningTasks || [],
        downloadToken,
        onProjectChange,
        getProjects,
        serviceIsAvailable,
        logout
    };
};
