import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router";

import message from "antd/es/message";

import { fetchPatent } from "../../api";
import { PatentDocument } from "../../constants/types";

import { PageWrapper } from "../../components/PageWrapper";
import { PatentViewer } from "../../components/Patent";

export default () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [patent, setPatent] = useState<PatentDocument | null>(null);
    const { patentId } = useParams<"patentId">();
    const navigate = useNavigate();

    useEffect(() => {
        if (!patentId) {
            return navigate("/");
        }

        if (!patent || patentId !== patent.id) {
            setLoading(true);
            fetchPatent(patentId)
                .then(
                    p => setPatent(p)
                ).catch(
                    () => {
                        message.error("Unable to fetch patent...");
                        setPatent(null);
                    }
                ).finally(() => {
                    setLoading(false);
                });
        }
    }, [patentId, patent, navigate]);

    return (
        <PageWrapper className="display">
            <PatentViewer
                layout="two-columns"
                loading={loading}
                showLink={false}
                patent={patent}
                markers={[]}
            />
        </PageWrapper>
    );
};
