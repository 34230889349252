import uniq from "lodash/uniq";
import find from "lodash/find";
import flatMap from "lodash/flatMap";

import Color from "color";
import { notEmpty } from "./types";
import { stringToColor } from ".";

export interface Marker {
    key: string,
    counter: number,
    items: string[],
    color: Color,
    enabled: boolean
}

const occurrency = (marker: string | RegExp, documents: any[], fields: string[]) => {
    const response: string[] = [];
    const re = typeof (marker) === "string"
        ? new RegExp(marker, 'g')
        : marker;

    if (documents) {
        documents.forEach(element => {
            try {
                fields.forEach((field: string) => {
                    if (element[field]) {
                        const matches = Array.isArray(element[field])
                            ? flatMap(element[field], (f: string) => f.match(re))
                            : element[field].match(re) || [];
                        response.push(...matches);
                    }
                });
            } catch (error) {
                console.error(error, marker, element);
            }
        });
    }

    return response.map(el => el && el.toLowerCase()).filter(notEmpty);
};

const getGroupMarkers = (groups: string[], documents: any[], fields: string[]): Marker[] => groups.map(
    (word) => ({
        key: word,
        counter: occurrency(word, documents, fields).length,
        items: [word],
        color: stringToColor(word),
        enabled: true
    })
);

const cleanBadItems = (list: string[]) => list.filter(el => el && el !== '' && el !== '\\');

type BuildMarker = (searchQuery: string | undefined | null, documents: any[], fields?: string[]) => Marker[];

export const buildMarkers: BuildMarker = (searchQuery, documents, fields = ['title', 'abstract', 'claims', 'description']) => {
    let markers = [] as Marker[];

    if (!searchQuery) {
        return markers;
    }

    try {
        let query = searchQuery;
        query = query.replace(/\{.*?\}/g, ""); // strip out "Complex Phrase"
        query = query.replace(/\s(?:OR|AND|NOT|-|\+)\s/gi, ' ');
        query = query.replace(/\t/g, ' ');
        query = query.replace(/\s{2,}/g, ' ');
        query = query.replace(/\s-/gi, ' ');
        query = query.replace(/(?:\+)/gi, ' ');
        query = query.replace(/\(|\)/g, ' ');
        query = query.replace(/[*]/g, "([a-z]+)"); // * replaced with [a-z]+
        query = query.replace(/[?]/g, "([a-z])"); // ? replaced with [a-z]
        query = query.replace(/(\w+):/g, ""); // field replace
        query = query.replace(/\[[^\]]+\]/g, ""); // field replace
        query = query.replace(/&quot;/g, '"'); // encodin quotes
        query = query.replace(/\{.*?\}/g, ""); // strip out "Complex Phrase"
        query = query.replace(/\(\+\)/g, ""); // removing (+) not sure about it

        const groups = uniq(cleanBadItems(query.replace(/"(.*?)"(~\d+)*/g, "").split(' '))).map((el: string) => el.toLowerCase());
        markers = getGroupMarkers(groups, documents, fields);

        let advancedGroups: (Marker | null)[] = [];
        advancedGroups = uniq(
            query.match(/"(.*?)"(~\d+)*/g)?.map(el => el.replace(/"+/g, "").trim())
        )?.map(
            (key: string): null | Marker => {
                const statement = key.replace('~', " ").trim();
                let marker = null;
                let comp = null;
                let re = null;
                let matched = [];
                if (!statement && statement === '' || find(statement.split(' '), e => e === '\\')) {
                    return null;
                }
                switch (statement.split(' ').length) {
                    case 2: // "pera banana"
                        matched = occurrency(new RegExp(statement, 'g'), documents, fields);
                        marker = { key, items: [...matched], counter: matched.length, color: stringToColor(key), enabled: true };
                        break;
                    case 3: // "pera banana~n"
                        comp = statement.split(' ');
                        re = new RegExp(`(?:${comp[0]})(?:\\W+\\w+){0,${comp[2]}}?\\W+(?:${comp[1]})`, 'g');
                        matched = occurrency(re, documents, fields);
                        marker = { key, items: [...matched], counter: matched.length, color: stringToColor(key), enabled: true };
                        break;
                    case 4: // "pera pippo banana~n"
                        comp = statement.split(' ');
                        re = new RegExp(`(?:${comp[0]})(?:\\W+\\w+){0,${comp[3]}}?\\W+(?:${comp[1]})(?:\\W+\\w+){0,${comp[3]}}?\\W+(?:${comp[2]})`, 'g');
                        matched = occurrency(re, documents, fields);
                        marker = { key, items: [...matched], counter: matched.length, color: stringToColor(key), enabled: true };
                        break;
                    default:
                        break;
                }
                return marker;
            }
        );
        return ([...markers, ...advancedGroups]).filter(notEmpty);
    } catch (error) {
        console.error(error);
    }

    return markers;
};

export const mergeMarkers = (initial: Marker[], current: Marker[]): Marker[] => {
    const statusMap = new Map<string, boolean>(initial.map(m => [m.key, m.enabled]));
    current.forEach(m => {
        const enabled = statusMap.get(m.key);
        if (enabled !== undefined) {
            m.enabled = enabled;
        }
    });
    return current;
};
