import React from "react";
import { Route, Routes } from "react-router-dom";

import Parameters from "./Parameters";
import Patents from "./Patents";
import Display from "./Display";

export const SearchRoutes = () => (
    <Routes>
        <Route path="patents" element={<Patents />} />
        <Route path="display/:patentId" element={<Display />} />
        <Route path="parameters" element={<Parameters />} />
    </Routes>
);
