import React from "react";

import { MetadataInterface } from "../constants/types";

import Tag from "antd/es/tag";

interface MetadataProps {
    obj: MetadataInterface | undefined;
}

const Metadata: React.FC<MetadataProps> = ({ obj }) => obj && obj.metadata
    ? (
        <>
            {
                Object.entries(obj.metadata).map(([k, v]) => <Tag color="gold" key={k}>{k}: {v}</Tag>)
            }
        </>
    )
    : null;

export default Metadata;
