import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import axios from 'axios';
import FileSaver from 'file-saver';

import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Spin from 'antd/es/spin';
import Avatar from 'antd/es/avatar';
import Tag from 'antd/es/tag';
import Menu from 'antd/es/menu';

import PlusOutlined from '@ant-design/icons/PlusOutlined';
import MinusOutlined from '@ant-design/icons/MinusOutlined';
import BranchesOutlined from '@ant-design/icons/BranchesOutlined';
import CloudDownloadOutlined from '@ant-design/icons/CloudDownloadOutlined';
import CloudUploadOutlined from '@ant-design/icons/CloudUploadOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import ContactsOutlined from '@ant-design/icons/ContactsOutlined';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import ShoppingCartOutlined from '@ant-design/icons/ShoppingCartOutlined';

import Editor from '@monaco-editor/react';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

import { useSearch, SearchPayload, SearchResult } from '../../hooks/useSearch';

import {
    SearchBar,
    SearchPagination,
    SearchItem,
    ErrorMessage,
    EmptyResults,
} from '../../components/Search';

import { PageWrapper } from '../../components/PageWrapper';

import Formatter from '../../components/Formatter';
import { MONACO_CONFIG } from '../../constants';
import HelpMenu from '../../components/HelpMenu';
import Affix from 'antd/es/affix';

interface Param {
    author: string;
    client: string;
    context_label: string;
    context_name: string;
    label: string;
    updated: string;
    tags: string[];
    tags_facet: string[];
    order_label: string;
    order_code: string;
    order_type: string;
    input_type: string;
    output_type: string;
    entity_type: string;
    entity_type_facet: string;
    value: string;
    lang: string;
}

interface Facet {
    name: string;
    count: number;
}

interface ParamItemProps {
    param: Param;
    onClick: (param: Param) => void;
}

interface ParamFacetProps {
    facet: Facet;
}

const ParamFacetLabel: React.FC<ParamFacetProps> = ({ facet }) => (
    <div className="facet">
        <div className="name">{facet.name.toUpperCase()}</div>
        <div className="count">{facet.count}</div>
    </div>
);

interface ParamFacetsProps {
    show: boolean;
    facets?: Facet[];
    active: string;
    onClick: (facet_name: string) => void;
}

const ParamFacets: React.FC<ParamFacetsProps> = ({ show, facets, active, onClick }) => {
    if (!facets || !show) {
        return null;
    }

    const all_count = facets!.reduce((acc, f) => f.count + acc, 0);

    const items = [
        {
            key: 'all',
            label: <ParamFacetLabel facet={{ name: 'all', count: all_count }} />,
        },
        ...facets!.map((f) => {
            return {
                key: f.name,
                label: <ParamFacetLabel facet={f} />,
            };
        }),
    ];

    return (
        <div className="params-facets">
            <Menu
                onClick={(e) => onClick(`${e.key}`)}
                mode="horizontal"
                selectedKeys={[active]}
                items={items}
            />
        </div>
    );
};

interface ParamTagsProps {
    tags: string[] | null;
}

const ParamTags: React.FC<ParamTagsProps> = ({ tags }) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    if (!tags || tags.length === 0) {
        return null;
    }

    const visibleTags = expanded ? tags.length : Math.min(tags.length, 3);

    const showButton = tags.length > 3;

    const icon = expanded ? <MinusOutlined /> : <PlusOutlined />;

    const ts = tags.slice(0, visibleTags);

    return (
        <div className="taglist">
            {ts.map((t, idx) => (
                <Tag key={idx}>{t}</Tag>
            ))}
            {showButton && (
                <Button icon={icon} size={'small'} onClick={(e) => setExpanded(!expanded)} />
            )}
        </div>
    );
};

interface ParamMetaProps {
    meta: string | null;
    icon: React.ReactNode;
}

const ParamMeta: React.FC<ParamMetaProps> = ({ meta, icon }) => {
    return meta ? (
        <Col span={12}>
            <div className="meta">
                {icon && <>{icon}&nbsp;</>}
                {meta}
            </div>
        </Col>
    ) : null;
};

const ParamItem: React.FC<ParamItemProps> = ({ param, onClick }) => {
    const code = param.order_code
        ? `${param.order_code} - ${param.context_label}`
        : param.context_label;

    return (
        <SearchItem
            avatar={<Avatar size="small">{param.entity_type[0].toUpperCase()}</Avatar>}
            title={param.label}
            onClick={() => onClick(param)}
            summary={param.value}
        >
            <ParamTags tags={param.tags} />
            {code && (
                <Row>
                    <ParamMeta meta={code} icon={<BranchesOutlined />} />
                </Row>
            )}
            {(param.input_type || param.output_type) && (
                <Row>
                    <ParamMeta meta={param.input_type} icon={<CloudUploadOutlined />} />
                    <ParamMeta meta={param.output_type} icon={<CloudDownloadOutlined />} />
                </Row>
            )}
            <Row>
                <ParamMeta meta={param.author} icon={<UserOutlined />} />
                <ParamMeta meta={param.client} icon={<ContactsOutlined />} />
                <ParamMeta meta={param.order_type} icon={<ShoppingCartOutlined />} />
                <ParamMeta meta={Formatter.date(param.updated, null)} icon={<CalendarOutlined />} />
            </Row>
        </SearchItem>
    );
};

interface ParamViewerProps {
    param?: Param;
    search: string | undefined | null;
}

const downloadParam = (param: Param) => {
    const label = param.label + '.txt';
    const blob = new Blob([param.value], { type: `text/plain;charset=utf-8` });
    FileSaver.saveAs(blob, label);
};

const ParamViewer: React.FC<ParamViewerProps> = ({ param, search }) => {
    const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);

    const highlightSearch = useCallback(() => {
        if (editorRef.current) {
            const editor = editorRef.current;
            const model = editor.getModel();
            if (model && search) {
                const range = model.findMatches(
                    search,
                    false,
                    false,
                    false,
                    null,
                    false,
                    undefined
                );

                const selections = range.map((r) => {
                    return {
                        selectionStartLineNumber: r.range.startLineNumber,
                        selectionStartColumn: r.range.startColumn,
                        positionLineNumber: r.range.endLineNumber,
                        positionColumn: r.range.endColumn,
                    };
                });

                if (selections.length > 0) {
                    editor.setSelections(selections);
                } else {
                    editor.setSelection(new monaco.Selection(0, 0, 0, 0));
                }
            }
        }
    }, [search]);

    useEffect(() => {
        if (editorRef.current && param && search) {
            highlightSearch();
        }
        return () => {};
    }, [param, search, highlightSearch]);

    const handleEditorDidMount = (editor: monaco.editor.IStandaloneCodeEditor, _: any) => {
        editorRef.current = editor;
        highlightSearch();
    };

    if (!param) {
        return null;
    }

    return (
        <div className="param-viewer">
            <div className="header">
                <span className="label">{param.label}&nbsp;</span>

                <Button
                    size="small"
                    onClick={(e) => downloadParam(param)}
                    icon={<CloudDownloadOutlined />}
                >
                    Download
                </Button>
            </div>
            <div className="param-content">
                <Editor
                    value={param.value}
                    onMount={handleEditorDidMount}
                    options={{
                        ...MONACO_CONFIG,
                        readOnly: true,
                    }}
                />
            </div>
        </div>
    );
};

const doSearch = (search: SearchPayload): Promise<SearchResult<Param, Facet>> =>
    axios.post('/api/services/pa/search/params', search);

const Parameters: React.FC = ({}) => {
    const { search, setSearch, loading, error, results } = useSearch<Param, Facet>(doSearch);
    const [selected, setSelected] = useState<Param | undefined>();
    const params = results?.docs;

    const showParam = (param: Param) => {
        setSelected(param);
    };

    const renderItems = () => {
        if (!search.query) {
            return null;
        }

        if (results.num_found === 0 && !loading) {
            return <EmptyResults />;
        }

        return params.map((param, idx) => (
            <ParamItem key={idx} param={param} onClick={showParam} />
        ));
    };

    const items = renderItems();

    const docs = useMemo(
        () => (
            <HelpMenu
                placement="bottomRight"
                sections={[
                    {
                        label: 'Restrict search to param content:',
                        items: ['value:[]'],
                    },
                    {
                        label: 'or to param metadata:',
                        items: ['meta:[]'],
                    },
                    {
                        label: 'Available metadata fields:',
                        items: [
                            '"entity_type": param type',
                            '"context_name": type of context',
                            '"context_label": project context name',
                            '"context_value": project context id',
                            '"tags": user defined project tags',
                            '"client": client name',
                            '"order_label": human readable order name',
                            '"order_code": order code',
                            '"order_type": type of order',
                            '"input_type": input given by client',
                            '"output_type": artifacts produced',
                            '"lang": language used',
                        ],
                    },
                ]}
            />
        ),
        []
    );

    return (
        <PageWrapper className="search">
            <SearchBar
                placeholder="Search parameters"
                defaultValue={search.query as string}
                disabled={loading}
                onSearch={(value) => setSearch({ query: value })}
            >
                {docs}
            </SearchBar>
            <Row>
                <Col span={24}>
                    <ParamFacets
                        show={!!search.query}
                        active={search.entity_type || 'all'}
                        facets={results.facets}
                        onClick={(facet_name) => setSearch({ ...search, entity_type: facet_name })}
                    />
                </Col>
            </Row>
            <Spin spinning={loading} size="large">
                <ErrorMessage
                    error={error}
                    loading={loading}
                    message={'System error, try again later...'}
                >
                    <Row className="item-container" gutter={16}>
                        <Col span={12}>
                            <div>
                                {items}
                                <SearchPagination
                                    results={results}
                                    onChange={(page, page_size) =>
                                        setSearch({ ...search, page, page_size: page_size || 10 })
                                    }
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <Affix offsetTop={64}>
                                <ParamViewer param={selected} search={search.query} />
                            </Affix>
                        </Col>
                    </Row>
                </ErrorMessage>
            </Spin>
        </PageWrapper>
    );
};

export default Parameters;
