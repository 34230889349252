import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import orderBy from 'lodash/orderBy';
import find from 'lodash/find';

import axios from 'axios';

import ReactEcharts, { EChartsOption } from 'echarts-for-react';

import message from 'antd/es/message';
import Affix from 'antd/es/affix';
import Drawer from 'antd/es/drawer';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';
import Row from 'antd/es/row';
import Col from 'antd/es/col';

import Tag from 'antd/es/tag';
import Switch from 'antd/es/switch';
import Skeleton from 'antd/es/skeleton';
import Space from 'antd/es/space';

const { CheckableTag } = Tag;

import EditOutlined from '@ant-design/icons/EditOutlined';
import HighlightOutlined from '@ant-design/icons/HighlightOutlined';
import SaveOutlined from '@ant-design/icons/SaveOutlined';

import { AppContext } from '../../contexts';

import { useSearch, SearchPayload, SearchResult, DoSearch } from '../../hooks/useSearch';

import { saveParamAndValue, fetchPatent } from '../../api';

import { EmptyPatent, Facet, FacetObject, Patent, PatentDocument, SearchFacet } from '../../constants/types';

import { PageWrapper } from '../../components/PageWrapper';

import { SearchCounter, SearchBar, ErrorMessage, SearchPagination, EmptyResults } from '../../components/Search';

import { buildMarkers, mergeMarkers, Marker } from '../../utils/highlight';

import { PatentViewer, PatentItem, PatentList } from '../../components/Patent';

import ToolsDrawer from '../../components/ToolDrawer';
import TermsDistributionTool from '../../components/tools/TermsDistributionTool';

interface MarkerListProps {
    markers: Marker[];
    toggleMarker?: (marker: Marker) => void;
}

const MarkerList: React.VFC<MarkerListProps> = ({ markers, toggleMarker }) => (
    <div>
        {orderBy(
            markers.filter((el) => el.counter > 0),
            ['counter'],
            ['desc']
        ).map((marker, key) => (
            <CheckableTag
                key={key}
                checked={marker.enabled}
                onChange={() => toggleMarker && toggleMarker(marker)}
                style={{
                    color: 'black',
                    margin: '6px',
                    backgroundColor: marker.enabled ? marker.color.toString() : '#f9f9f9',
                }}
            >
                {`${marker.key} (${marker.counter})`}
            </CheckableTag>
        ))}
    </div>
);

interface MarkerDrawerProps {
    markers: Marker[];
    toggleAllMarkers?: () => void;
    toggleMarker?: (marker: Marker) => void;
}

const MarkerDrawer: React.VFC<MarkerDrawerProps> = ({ markers, toggleAllMarkers, toggleMarker }) => {
    const [markerListVisible, setMarkerListVisible] = useState<boolean>(false);

    return (
        <>
            {markers.length > 0 && (
                <Button
                    className="drawer-button marker-drawer-button"
                    icon={<HighlightOutlined />}
                    onClick={() => setMarkerListVisible(true)}
                />
            )}

            <Drawer
                width={320}
                title={
                    <div className="justify-between">
                        <span>Markers</span>
                        <Button size="small" icon={<EditOutlined />} onClick={toggleAllMarkers} />
                    </div>
                }
                placement="right"
                closable={false}
                visible={markerListVisible}
                onClose={() => setMarkerListVisible(false)}
            >
                <MarkerList markers={markers} toggleMarker={toggleMarker} />
            </Drawer>
        </>
    );
};

const round = (n: number): number => {
    const m = 10.0 ** Math.floor(Math.log10(n * 1.1));
    return Math.ceil(n / m) * m;
};

declare type ColSpanType = number | string;

interface FacetsObjectComponentProps<T> {
    name: string;
    facets: Facet<T>[];
    span: ColSpanType | undefined;
}

const FacetObjectHistogram: React.FC<FacetsObjectComponentProps<number>> = ({ name, facets, span = 8 }) => {
    const data = useMemo(() => {
        if (!!facets && facets.length > 0) {
            const sorted = facets.sort((a, b) => a.name - b.name);
            const xs = sorted.map((f) => f.name);
            const ys = sorted.map((f) => f.count);
            const sorted_data = sorted.map((f) => [new Date(f.name, 0), f.count]);

            const x_range = [sorted[0].name, sorted[sorted.length - 1].name];

            const y_max = sorted.reduce(
                (acc, y) => {
                    if (y.count > acc.count) {
                        acc.count = y.count;
                    }
                    return acc;
                },
                { name: -1, count: 0 }
            ).count;
            return { xs, ys, data: sorted_data, x_range, y_range: [0, round(y_max)] };
        }
        return { xs: [], ys: [], data: [], x_range: [0, 0], y_range: [0, 0] };
    }, [facets]);

    const x_dt = 50.0; // Initial time from now
    const x_perc = 100.0 - (x_dt / (data.x_range[1] - data.x_range[0])) * 100.0;

    const option: EChartsOption = {
        grid: {
            top: 20,
            left: '10%',
            right: '10%',
        },
        tooltip: {
            trigger: 'axis',
            position: (pt: any[]) => {
                return [pt[0], '10%'];
            },
            formatter: (params: any) => {
                const year = params[0].data[0].getFullYear();
                const value = params[0].data[1];
                const marker = params[0].marker;
                return `<div><strong>${year}</strong></div><div>${marker} ${value}</div>`;
            },
        },
        dataZoom: [
            {
                type: 'inside',
                start: x_perc,
                end: 100,
            },
            {
                start: x_perc,
                end: 100,
            },
        ],
        xAxis: {
            type: 'time',
        },
        yAxis: {
            type: 'value',
            boundaryGap: false,
            min: data.y_range[0],
            max: data.y_range[1],
        },
        series: [
            {
                data: data.data,
                type: 'bar',
                barCategoryGap: '5%',
            },
        ],
    };

    return (
        <Col span={span}>
            <div className="facets-entry">
                <div className="sub-title">{name}</div>
                <div className="facets-values">
                    <ReactEcharts option={option} style={{ height: '200px' }} />
                </div>
            </div>
        </Col>
    );
};

interface FacetsItemProps {
    show: boolean;
    facets: SearchFacet | null;
}

const FacetsItem: React.FC<FacetsItemProps> = ({ show, facets }) => {
    const loading = !facets;

    if (!show) {
        return null;
    }

    const facet_ranges_content =
        facets && facets.facet_ranges
            ? Object.entries(facets.facet_ranges).map(([name, fcts]) => (
                  <FacetObjectHistogram span={24} key={name} name={name} facets={fcts} />
              ))
            : null;

    const facet_fields_content =
        facets && facets.facet_fields && facets.facet_fields
            ? Object.entries(facets.facet_fields).map(([name, fcts]) => {
                  return (
                      <Col span={8} key={name}>
                          <div className="facets-entry">
                              <div className="sub-title">{name}</div>
                              <div className="facets-values">
                                  {fcts.map(({ name: fct_name, count: fct_count }) => (
                                      <div key={fct_name} className="facet">
                                          <span className="name">{fct_name}</span>
                                          &nbsp;
                                          <span className="badge">{fct_count}</span>
                                      </div>
                                  ))}
                              </div>
                          </div>
                      </Col>
                  );
              })
            : null;

    return (
        <div className="patents-facets">
            <div className="facets">
                <Skeleton loading={loading} title={false} paragraph={{ rows: 3 }}>
                    <div className="header">
                        <div className="title">Search Facets</div>
                    </div>
                    <div className="facets-content">
                        <Row>{facet_ranges_content}</Row>
                        <Row>{facet_fields_content}</Row>
                    </div>
                </Skeleton>
            </div>
        </div>
    );
};

const doSearch: DoSearch<Patent, void> = (search) => axios.post('/api/services/pa/search/patents', search);

const doFacets = (search: SearchPayload): Promise<SearchFacet> =>
    axios.post('/api/services/pa/search/patents/facets', search);

const Patents: React.FC = ({}) => {
    const navigate = useNavigate();
    const { search, setSearch, loading, error, results } = useSearch<Patent>(doSearch);
    const [query, setQuery] = useState<string>(search.query || '');
    const [patentLoading, setPatentLoading] = useState<boolean>(false);
    const [selectedPatent, setSelectedPatent] = useState<PatentDocument | null>(null);
    const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
    const [saveQueryForm] = Form.useForm();
    const { activeProjectId, activeProject, paramTypes } = useContext(AppContext);
    const [showFacet, setShowFacet] = useState<boolean>(false);
    const [facets, setFacets] = useState<SearchFacet | null>(null);
    const [markers, setMarkers] = useState<Marker[]>([]);

    const patents = results?.docs;
    const canSave = patents.length > 0;
    const showCounter = !!search.query;

    useEffect(() => {
        if (error) {
            setMarkers([]);
        }
    }, [error]);

    useEffect(() => {
        setMarkers((mks) => {
            const docs = selectedPatent ? [...results.docs, selectedPatent] : results.docs;
            return mergeMarkers(mks, buildMarkers(search.query, docs));
        });
    }, [results.docs, search.query, selectedPatent]);

    useEffect(() => {
        let isSubscribed = true;

        if (!showFacet) {
            setFacets(null);
        } else {
            doFacets(search).then((ftcs) => {
                if (isSubscribed) {
                    setFacets(ftcs);
                }
            });
        }

        return () => {
            isSubscribed = false;
        };
    }, [search, search.query, showFacet]);

    const onSearch = (value: string) => {
        if (value !== search.query || error) {
            setSelectedPatent(null);
            setSearch({ query: value });
        }
    };

    const showPatent = async (patentId: string) => {
        setPatentLoading(true);
        setSelectedPatent(EmptyPatent);
        const patentDocument = await fetchPatent(patentId);
        if (patentDocument) {
            setSelectedPatent(patentDocument);
        } else {
            message.error(`Unable to load ${patentId}`);
        }
        setPatentLoading(false);
    };

    const toggleAllMarkers = () => {
        const newState = !find(markers, (marker: Marker) => marker.enabled);
        const _markers = markers.map((m: Marker) => {
            m.enabled = newState;
            return m;
        });
        setMarkers(_markers);
    };

    const toggleMarker = (marker: Marker) => {
        const _markers = markers.map((m: Marker) => {
            if (m.key === marker.key) {
                m.enabled = !m.enabled;
            }
            return m;
        });
        setMarkers(_markers);
    };

    const showModal = () => {
        saveQueryForm.setFieldsValue({ label: '' });
        setShowSaveModal(true);
    };

    const hideModal = () => {
        setShowSaveModal(false);
    };

    const saveSearchQuery = () => {
        if (!activeProjectId) {
            message.warn('Please select a project!');
            return false;
        }

        saveQueryForm
            .validateFields()
            .then((values) => {
                const param = {
                    label: values.label,
                    project: { id: activeProjectId },
                    paramType: find(paramTypes, (pt) => pt.name === 'SearchQuery')!,
                    data: {},
                };
                const value_blob = new Blob([search.query as string], {
                    type: 'plain/text',
                });
                saveParamAndValue(param, value_blob).then((data) => {
                    hideModal();
                    message.success('Search query saved');
                    navigate(`/parameters/${data.id}`);
                });
            })
            .catch((errorInfo) => {
                hideModal();
                message.error('Unable to save parameter...');
                console.error(errorInfo);
            });
    };

    const toggleShowFacet = () => setShowFacet(!showFacet);

    const termsQueryReplace = (terms: string[]): boolean => {
        setQuery(terms.join(' '));
        return true;
    };

    const termsQueryAppend = (terms: string[]): boolean => {
        setQuery(`${search.query} ${terms.join(' ')}`);
        return true;
    };

    const solrSearchLabelPlaceholder = activeProject ? `${activeProject.label}-SearchQuery-##` : `SearchQuery-##`;

    return (
        <PageWrapper className="search">
            <SearchBar defaultValue={query} placeholder="Search patents" disabled={loading} onSearch={onSearch}>
                <Space>
                    <Button icon={<SaveOutlined />} onClick={showModal} disabled={!canSave}>
                        Save
                    </Button>
                    <Modal
                        title="Save Search Query"
                        visible={showSaveModal}
                        onOk={saveSearchQuery}
                        onCancel={() => setShowSaveModal(false)}
                    >
                        <Form name="file-param-edit" form={saveQueryForm} onFinish={saveSearchQuery}>
                            <Form.Item label="Label" name="label" rules={[{ required: false }]}>
                                <Input placeholder={solrSearchLabelPlaceholder} />
                            </Form.Item>
                        </Form>
                    </Modal>
                    <TermsDistributionTool
                        kind="query"
                        actions={[
                            {
                                label: 'Replace Current Query',
                                type: 'primary',
                                onClick: (output: string[]) => termsQueryReplace(output),
                            },
                            {
                                label: 'Append To Current Query',
                                type: 'primary',
                                onClick: (output: string[]) => termsQueryAppend(output),
                            },
                        ]}
                    />
                </Space>
            </SearchBar>

            <Row className="search-info">
                <Col span={24}>
                    <div className="facet-control">
                        <Switch onChange={toggleShowFacet} /> &nbsp; Show Facet
                    </div>
                    <SearchCounter loading={loading} show={showCounter} count={results.num_found} />
                </Col>
            </Row>

            <Spin spinning={loading} size="large">
                <ErrorMessage error={error} loading={loading} message={'System error, try again later...'}>
                    <Row className="item-container" gutter={16}>
                        <Col span={12}>
                            <FacetsItem show={showFacet} facets={facets} />

                            <PatentList
                                query={search.query}
                                loading={loading}
                                patents={patents}
                                markers={markers}
                                onSelect={showPatent}
                            />

                            <SearchPagination
                                results={results}
                                onChange={(page, page_size) =>
                                    setSearch({
                                        ...search,
                                        page,
                                        page_size: page_size || 10,
                                    })
                                }
                            />
                        </Col>
                        <Col span={12}>
                            {selectedPatent && (
                                <Affix offsetTop={64}>
                                    <PatentViewer
                                        loading={patentLoading}
                                        patent={selectedPatent}
                                        markers={markers}
                                        onCitationClick={showPatent}
                                    />
                                </Affix>
                            )}
                        </Col>
                        <ToolsDrawer />
                        <MarkerDrawer
                            markers={markers}
                            toggleAllMarkers={toggleAllMarkers}
                            toggleMarker={toggleMarker}
                        />
                    </Row>
                </ErrorMessage>
            </Spin>
        </PageWrapper>
    );
};

export default Patents;
